<template>
  <div class="dashboard-container">
    <!-- <div class="block" v-if="url">
      <iframe :src="url" width="100%" :height="ifheight+'px'" frameborder="0" scrolling="auto" ></iframe>
    </div> -->
    <component :is="currentRole"></component>
  </div>
</template>

<script>
import adminDashboard from './admin'
export default {
  name: 'dashboard',
  components: { adminDashboard },
  data() {
    return {
      currentRole: 'adminDashboard',
      url: '',
      ifheight:0,
    }
  },
  mounted() {
    // this.url ='https://www.qdhuade.com/' //大屏正式地址
    // this.url ='http://192.168.110.167:9999/#/shelvesVisualization' //大屏测试地址
    // this.url ='http://localhost:9099/#/shelvesVisualization' //大屏本地地址
    this.ifheight = document.documentElement.clientHeight - 35 - 45 -8
  },
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
  .dashboard-container{
    padding: 10px;
    min-height:calc(100% - 10px);
  }
</style>